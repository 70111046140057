<template>
  <v-container class="box-container">
    <v-row>
      <v-col>
        <div class="d-flex align-center mb-5">
          <v-avatar
            tile
            size="25"
            class="me-3"
          >
            <img
              src="@/assets/images/icons/announcement.svg"
              alt=""
            >
          </v-avatar>
          <h2 class="mb-0">
            {{ $t("Promotion") }} {{ $route.params.slug?$t("produit"):$t('boutique') }}
          </h2>
        </div>
      </v-col>
      <v-col cols="auto">
        <v-btn
          to="/promotions"
          color="primary"
        >
          {{ $t("Promotions") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-form
      ref="promotionForm"
      v-model="promotionForm"
      lazy-validation
      @submit.prevent="save"
    >
      <v-row>
        <v-col
          cols="12"
          lg="6"
        >
          <v-card>
            <v-card-title>
              <p class="font-weight-bold">
                {{ $t("Configuration") }}
              </p>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col
                  v-if="promotion.product_id"
                  cols="12"
                >
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-img :src="promotion.product?promotion.product.image:''">
                        <v-progress-circular
                          v-if="loadingProduct"
                          indeterminate
                        />
                      </v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>{{ promotion.product_id }} - {{ promotion.product?promotion.product.name:'' }}</v-list-item-title>
                      <v-list-item-subtitle>{{ $t("Produit") }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-autocomplete
                    v-model="promotion.promotion_type_id"
                    :label="$t('Type de promotion')"
                    :items="promotion_types"
                    item-value="id"
                    item-text="name"
                    :rules="typeRules"
                    outlined
                    :loading="loadingTypes"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  v-if="promotion.promotion_type_id != 1"
                >
                  <v-autocomplete
                    v-model="promotion.promotion_value_id"
                    :label="$t('Valuer de la promotion')"
                    :items="promotion_values.filter((e) => e.promotion_type_id == promotion.promotion_type_id).sort((a, b) => b.duration - a.duration)"
                    item-value="id"
                    item-text="name"
                    outlined
                    :rules="valueRules"
                    :disabled="!promotion.promotion_type_id"
                    :loading="loadingTypes"
                  >
                    <template #item="{ item }">
                      {{ item.duration }} {{ item.unit }}
                    </template>
                    <template #selection="{ item }">
                      {{ item.duration }} {{ item.unit }}
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col
                v-else
                  cols="12"
                  md="6"
                >
                  <v-autocomplete
                    v-model="promotion.duration"
                    :label="$t('Durée')"
                    :items="hours"
                    outlined
                    :rules="valueRules"
                    :disabled="!promotion.promotion_type_id"
                    :loading="loadingTypes"

                  >
                    <template #item="{ item }">
                      {{ item }} {{ $t("heures") }}
                    </template>
                    <template #selection="{ item }">
                      {{ item }} {{ $t("heures") }}
                    </template>
                  </v-autocomplete>

                </v-col>
                <v-col
                  cols="12"
                  :md="promotion.promotion_type_id == 1?'6':'12'"
                >
                  <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="promotion.startdate"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="frenchFormatedDate"
                        :label="$t('Choisir une date')"
                        append-icon="mdi-calendar"
                        readonly
                        outlined
                        v-bind="attrs"
                        :rules="dateRules"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="promotion.startdate"
                      :min="minDate"
                      locale="fr"
                      first-day-of-week="1"
                      scrollable
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="modal = false"
                      >
                        {{ $t("Fermer") }}
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(promotion.startdate)"
                      >
                        {{ $t("OK") }}
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  v-if="promotion.promotion_type_id == 1"
                >
                  <v-dialog
                    ref="dialogTime"
                    v-model="modal2"
                    :return-value.sync="promotion.time"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="promotion.time"
                        :label="$t('Heures')"
                        append-icon="mdi-clock-time-four-outline"
                        readonly
                        outlined
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="modal2"
                      v-model="promotion.time"
                      format="24hr"
                      full-width
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="modal2 = false"
                      >
                        {{ $t("Annuler") }}
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialogTime.save(promotion.time)"
                      >
                        OK
                      </v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col>
                <v-col
                  v-if="newprice || promotion.promotion_type_id == 1"
                  cols="12"
                >
                  <v-text-field
                    v-model="promotion.promotion_price"
                    :label="$t('Prix vente flash')"
                    outlined
                    :rules="priceRules"
                    type="number"
                    @change="setPriceIntervale"
                  />
                </v-col>


                <v-col
                  v-if="(!$route.params.slug && promotion.promotion_type_id === 6) || ($route.params.slug && promotion.promotion_type_id === 5)"
                  cols="12"
                >
                  <v-autocomplete
                    v-model="promotion.categories_ids"
                    :placeholder="$t('Categories')"
                    :items="categories"
                    item-value="id"
                    item-text="name"
                    :loading="loadingCategories"
                    :search-input.sync="search"
                    outlined
                    multiple
                    clearable
                    deletable-chips
                    chips
                    :rules="categoriesRules"
                  />
                </v-col>
<!--                <v-col
                  v-if="promotion.promotion_type_id != 1"
                  cols="12"
                  :md="promotion.payment_method == 'card'?'6':'12'"
                >
                  <v-select
                    v-model="promotion.payment_method"
                    :label="$t('Method de paiement')"
                    :items="payment_methods"
                    item-value="id"
                    item-text="label"
                    outlined
                    :rules="paymentMethodRules"
                  />
                </v-col>
                <v-col
                  v-if="promotion.payment_method == 'card' && promotion.promotion_type_id != 1"
                  cols="12"
                  :md="promotion.payment_method == 'card'?'6':'12'"
                >
                  <v-select
                    v-model="promotion.card_type"
                    :label="$t('Type de carte')"
                    :items="card_types"
                    item-value="id"
                    item-text="label"
                    outlined
                    :rules="cardRules"
                  />
                </v-col>-->
                <v-col cols="12">
                  <v-checkbox
                    required
                    v-model="promotion.promotion_value_id"
                    :rules="termRules"
                  >
                    <template #label>
                      <span>{{ $t("En cochant la case, je confirme renoncer à mon droit de rétractation en vertu de l'article L.121-21-8 du Code de la consommation pour utiliser ces services.") }}</span>
                  </template></v-checkbox>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions v-if="$vuetify.breakpoint.lgAndUp">
              <v-btn
                block
                color="primary"
                type="submit"
                :disabled="!promotionForm"
                :loading="loading"
              >
                {{ $t("Enregistrer") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          lg="6"
        >
          <v-card>
            <v-card-title>
              <p class="font-weight-bold">
                {{ $t("Votre Commande") }}
              </p>
            </v-card-title>
            <v-card-text>
              <div class="d-flex justify-space-between">
                <p class="mb-0 grey--text text--darken-1">
                  {{ $t("Date début") }}:
                </p>
                <h4 class="font-600 mb-0">

                  {{ `${formatDateTime(promotion.datestart) || '-'}` }}
                </h4>
              </div>
              <div class="d-flex justify-space-between">
                <p
                  v-if="newprice"
                  class="mb-0 grey--text text--darken-1"
                >
                  {{ $t("Durée (entre 3h et 96h)") }}:
                </p>
                <p
                  v-if="!newprice"
                  class="mb-0 grey--text text--darken-1"
                >
                  {{ $t("Date Fin") }}:
                </p>
                <h4
                  v-if="!newprice"
                  class="font-600 mb-0"
                >
                  {{ formatDateTime(promotion.dateend) || '-' }}
                </h4>
              </div>
              <div class="d-flex justify-space-between">
                <p class="mb-0 grey--text text--darken-1">
                  {{ $t("Cout de la promotion") }}:
                </p>
                <h4 class="font-600 mb-0">
                {{ formatAmount(promotion.cost || 0 )}}
                </h4>
              </div>
              <v-divider class="my-4" />
              <div class="d-flex justify-space-between mb-2">
                <p class="mb-0 font-weight-bold text--darken-2">
                  {{ $t("Sous-total") }}:
                </p>
                <p class="mb-0 font-weight-bold">
                  {{ formatAmount(promotion.cost || 0 )}}
                </p>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-btn
                block
                color="primary"
                type="submit"
                :disabled="!promotionForm"
                :loading="loading"
              >
                {{ $t("Enregistrer") }}
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-alert
            v-if="promotion.promotion_type_id == 1"
            outlined
                    color="info" dense class="mt-10">
            <div class="text-h6">
              {{ $t("Informations sur vos frais Vente Flash.")}}
            </div>
            <p>
              {{ $t("Le montant de votre prix de vente flash détermine la commission de prestation que vous devrez payer si la transaction du produit est finalisée. Le montant sera déduit du montant de votre vente et vous recevrez une facture.")}}
            </p>
            <div class="d-flex justify-space-between border-grey px-1" v-for="pvalue in promotion_values.filter((e) => e.promotion_type_id == promotion.promotion_type_id)">
              <div>{{ $t("Prix vente flash") }}: {{ formatAmount(parseFloat((pvalue.price_min || "0").trim()))}} - {{ formatAmount(parseFloat((pvalue.price_max || "0").trim())) }}</div>
              <div>{{ formatAmount(parseFloat((pvalue.cost || "0").trim()))}}</div>
            </div>
          </v-alert>
          <v-alert
            v-if="promotion.promotion_type_id != null && promotion.promotion_type_id != 1"
            outlined
                    color="info" dense class="mt-10">
            <div class="text-h6">
              {{ $t("Informations des Frais des mises en Vendette de votre Boutique.")}}
            </div>

            <template v-for="pt in promotion_types.filter((e) => e.id == promotion.promotion_type_id)">
              <div :key="pt.id" class="d-flex justify-space-between border-grey px-1" v-for="pvalue in promotion_values.filter((e) => e.promotion_type_id == pt.id)">
                <div>{{ `${pt.name} ${pvalue.duration}${pvalue.unit}` }}</div>
                <div>{{ formatAmount(parseFloat((pvalue.cost || "0").trim()))}}</div>
              </div>
            </template>

          </v-alert>


        </v-col>
      </v-row>
    </v-form>
    <v-row >
      <v-col cols="12" md="6">
        <v-form
          v-if="promotion.promotion_type_id != 1"
          ref="paymentForm"
          v-model="paymentForm"
          lazy-validation
          @submit.prevent="handlePayment"
        >
          <v-card :flat="!showPayment">
            <v-card-title v-if="showPayment">{{ $t("Paiement") }}</v-card-title>
            <v-card-text >
              <div id="payment-element" ></div>
              <div id="error-message" ></div>
            </v-card-text>
            <v-divider v-if="showPayment"/>
            <v-card-actions v-if="showPayment">
              <v-btn
                id="submit"
                type="submit"
                color="primary"
                class="text-capitalize font-600"
                :loading="loadingSettings"
              >
                {{ $t("Payer") }}({{ formatAmount(promotion.cost || 0 )}})
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>

      </v-col>
    </v-row>
    <v-dialog
      v-model="confirm_dialog"
      width="400"
      persistent
    >
      <v-form
        ref="saveForm"
        v-model="saveForm"
        lazy-validation
        @submit.prevent="savePromotion"
      >
        <v-card :loading="loadingSettings">
          <v-card-title>{{ $t("Confirmer") }}</v-card-title>
          <v-card-text
            v-if="loading"
            style="height: 200px"
          >
            <v-row
              justify="center"
              align="center"
              class="fill-height"
            >
              <v-col
                cols="auto"
                class="text-center"
              >
                <v-progress-circular
                  indeterminate
                  color="primary"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text v-else>
            <p>
              {{ $t("Est vous sur de vouloir continuer?") }}
            </p>
            <div v-if="promotion.promotion_type_id != 1">
              <p v-if="promotion.payment_method == 'wallet'">
                <strong>&euro;{{ promotion.cost || 0 }}</strong> {{ $t("seront déduits de votre portefeuille") }}
              </p>
              <div v-else>
                {{ $t("Saisissez les informations relatives à votre carte") }}

<!--                <v-row>
                  <v-col
                    cols="12"
                    class="pb-0"
                  />
                  <v-col
                    cols="12"
                    class="pb-0"
                  >
                    <p class="text-14 mb-1">
                      {{ $t("Nom sur la carte") }}
                    </p>
                    <v-text-field
                      v-model="cardName"
                      :rules="cardNameRules"
                      outlined
                      dense
                      hide-details
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    class="pb-0"
                  >
                    <p class="text-14 mb-1">
                      {{ $t("Numéro de carte") }}
                    </p>
                    <v-text-field
                      v-model="cardNumber"
                      v-mask="'#### #### #### ####'"
                      :rules="cardNumberRules"
                      :value="cardNumber"
                      placeholder="0000 0000 0000 0000"
                      outlined
                      dense
                      hide-details
                    />
                  </v-col>
                  <v-col
                    cols="9"
                    xl="8"
                    lg="8"
                  >
                    <p class="text-14 mb-1">
                      {{ $t("Date d'expiration") }}
                    </p>
                    <v-text-field
                      v-model="cardExpirationDate"
                      v-mask="'##/##'"
                      :rules="cardExpirationDateRules"
                      :value="cardExpirationDate"
                      placeholder="00/00"
                      outlined
                      dense
                      hide-details
                    />
                  </v-col>
                  <v-col
                    cols="3"
                    xl="4"
                    lg="4"
                  >
                    <p class="text-14 mb-1">
                      CVV
                    </p>
                    <v-text-field
                      v-model="cardCvx"
                      v-mask="'###'"
                      :rules="cardCvxRules"
                      :value="cardCvx"
                      placeholder="000"
                      outlined
                      dense
                      hide-details
                    />
                  </v-col>
                </v-row>-->
              </div>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions v-if="!loading">
            <v-btn
              small
              outlined
              @click.stop="confirm_dialog = false"
            >
              {{ $t("Fermer") }}
            </v-btn>
            <v-spacer />
            <v-btn

              type="submit"
              color="primary"
              class="text-capitalize font-600"
              :disabled="!paymentForm"
              :loading="loadingSettings"
            >
              {{ $t("Payer") }}({{ formatAmount(promotion.cost || 0 )}})
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.active"
      :color="snackbar.color"
    >
      {{ snackbar.text }}
    </v-snackbar>
  </v-container>
</template>
<script>
  import Vue from 'vue'
  import * as url from 'url'
  import CategoryPromotionForm from '../../components/shops/CategoryPromotionForm.vue'
  import { mapState } from 'vuex'
  import i18n from '../../i18n'
  import { VueMaskDirective } from 'v-mask'
  Vue.directive('mask', VueMaskDirective)
  Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf())
    date.setDate(date.getDate() + days)
    return date
  }
  export default {
    components: {

    },
    props: {
    },
    data () {
      return {
        hours: (Array.from({ length: 94 }, (_, i) => i + 3)).sort((a, b) => b - a),
        time: null,
        isSidebar: false,
        loadingProduct: false,
        dateMenu: false,
        activePicker: null,
        promotionDialog: null,
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu: false,
        modal: false,
        modal2: false,
        menu2: false,
        showPayment: false,
        page: 0,
        itemsPerPage: 5,
        pagination: { itemsPerPage: 5, page: 1 },
        item: {},
        items: [],
        total: 0,
        loading: false,
        loadingTypes: false,
        timeout: null,
        range: [0, 100, 500, 1000],
        itemsTwo: ['Low to High', 'High to Low', 'Date'],
        search: '',
        url: '/shop-promotion',
        confirm_dialog: false,
        loadingDelete: false,
        snackbar: { active: false, text: '', color: 'info' },

        baseurl: '/',
        payment_type: {},
        price: '',
        password: '',
        condition_legal: false,
        currency: 'EUR',
        CardType: 'CB_VISA_MASTERCARD',
        payment_method: 'wallet',
        newprice: false,
        paymentform: false,
        costlable: i18n.t('cout de la promotion'),
        minDate:  (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        promotion: {
          startdate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
          datestart: null,
          dateend: null,
          promotion_type: {},
          promotion_value: {},
          categories_ids: [],
          payment_method: 'card',
          card_type: 'CB_VISA_MASTERCARD',
          duration: 3,
          time: null,
          cost: 0,
          product: {},
          product_id: null,
        },
        promotions: [],
        promotion_types: [],
        promotion_values: [],
        promotion_value_items: [],
        categoryselected: [],
        loadingCategories: false,
        categories: [],
        payment_methods: [
          { id: 'wallet', label: i18n.t('Payer avec mon wallet') },
          { id: 'card', label: i18n.t('Payer par carte') },
        ],
        card_types: [
          { id: 'CB_VISA_MASTERCARD', label: 'CB_VISA_MASTERCARD' },
        ],
        promotionValid: false,
        promotionForm: null,
        paymentForm: null,
        saveForm: null,
        typeRules: [v => !!v || i18n.t('Le type de la promotion est requis.')],
        valueRules: [v => !!v || i18n.t('La valeur de la promotion est requis.')],
        paymentMethodRules: [v => !!v || i18n.t('Le method de paiement de la promotion est requis.')],
        priceRules: [v => !!v || i18n.t('Le prix de vente flash est requis.')],
        cardRules: [v => !!v || i18n.t('Le type de carte est requis.')],
        dateRules: [v => !!v || i18n.t('La date de la promotion est requis.')],
        categoriesRules: [v => !!(v && v.length) || i18n.t('Au moins une catégorie est requise.')],
        cardCvxRules: [v => !!v || i18n.t('Le CVX est requis')],
        cardExpirationDateRules: [v => !!v || i18n.t('La date d\'expiration de la carte est requise')],
        cardNumberRules: [v => !!v || i18n.t('Le numéro de la carte est requis')],
        cardNameRules: [v => !!v || i18n.t('Le nom sur le panier est obligatoire')],
        termRules: [v => !!v || i18n.t('Vous devez accepter les termes et conditions')],

        cardNumber: null,
        cardName: null,
        cardCvx: null,
        cardExpirationDate: null,
        validAddressForm: false,
        stripe_pk: null,
        stripe: null,
        loadingSettings: false,
        settings: {},
        elements: null,
        clientSecret: null,
        return_url: null,
        payment: null,
      }
    },
    computed: {
      ...mapState({
        user: 'user',
        login: 'login',
        displayNotification: 'display_notification',
      }),
      numberOfPages () {
        return Math.ceil(this.total / this.itemsPerPage)
      },
      frenchFormatedDate() {
        return this.formatDate(this.promotion.startdate)
      },
      params (nv) {
        return { /*...this.promotion,*/ promotion_type_id: this.promotion.promotion_type_id }
      },
      dateStart(nv) {
        return this.promotion.datestart
      },
      startTime(nv) {
        return this.promotion.time
      },
      promotionPrice(nv) {
        return this.promotion.promotion_price
      }
    },
    watch: {
      dateStart () {
        this.calculatePromotion()
      },
      startTime () {
        this.calculatePromotion()
      },
      promotionPrice () {
        this.calculatePromotion()
      },
      search () {
        if (this.search) {
          if (this.timeout) {
            clearTimeout(this.timeout)
          }
          this.timeout = setTimeout(() => {
            this.getCategories()
          }, 1500)
        }
      },
      params: {
        handler () {
          this.calculatePromotion()
        },
        deep: true,
      },
    },
    mounted () {
      this.url = this.$route.path
    },
    created () {
      const currentTime = new Date()
      //currentTime.setMinutes(currentTime.getMinutes() + 30);
      const hours = currentTime.getHours().toString().padStart(2, '0')
      const minutes = currentTime.getMinutes().toString().padStart(2, '0')
      const formattedTime = `${hours}:${minutes}`
      this.promotion.time = formattedTime
      // window.addEventListener('scroll', this.handleScroll)
      this.getTypes()
      this.getCategories()
      if (this.$route.params.slug) {
        this.promotion.product_id = this.$route.params.slug
       this.getProduct()
      }
      //this.getSettings()
    },
    destroyed () {
      // window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
      setupStripe() {
        console.log('clientSecret')
        const options = {
          clientSecret: this.clientSecret,
          // mode: 'intent',
          //currency: 'eur',
          //'payment_method_types': ['card'],
          // Fully customizable with appearance API.
          appearance: {
            variables: {
              colorPrimary: "#32325d",
              colorBackground: "#ffffff",
              colorText: "#32325d",
            },
            rules: {
              ".Input": {
                "box-shadow": "none",
                "border": "1px solid #ced4da",
              },
              ".Input:focus": {
                "border": "1px solid #007bff",
              },
              ".Input--invalid": {
                "border": "1px solid red",
              },
            },
          },
        };

        // Set up Stripe.js and Elements to use in checkout form
        this.elements = this.stripe.elements(options);

        // Create and mount the Payment Element
        const paymentElementOptions = { layout: 'accordion'};
        const paymentElement = this.elements.create('payment', paymentElementOptions);
        paymentElement.mount('#payment-element');

      },
      getSettings () {
        this.loadingSettings = true
        // eslint-disable-next-line no-undef
        axios.get('/checkout_settings').then((response) => {
          this.loadingSettings = false
          if (response.data) {
            this.settings = response.data

            this.stripe_pk = this.settings.stripe_pk
            // Set your publishable key: remember to change this to your live publishable key in production
            // See your keys here: https://dashboard.stripe.com/apikeys
            this.stripe = Stripe(this.stripe_pk, {
              locale: "fr", // Change to your desired locale
            });

            this.setupStripe()

          }
        })
      },
      calculatePromotion() {
        console.log("duration",this.promotion.duration)
        console.log("time",this.promotion.time)
        if (this.promotion.startdate) {
          var dateob = new Date(this.promotion.startdate + ' ' + this.promotion.time)
          //var fromdate = dateob.addDays(1)
          var fromdate = dateob.addDays(0)
          this.promotion.datestart = fromdate.getFullYear() + '-' + (fromdate.getMonth() + 1) + '-' + fromdate.getDate() + (this.promotion.time? (  " " + fromdate.getHours() + ':' + fromdate.getMinutes()):'')
          console.log("new date", this.promotion.datestart)
        }
        var proValue = this.promotion_values.find((e) => e.id == this.promotion.promotion_value_id) || { duration: 0 }
        if (!this.promotion.datestart || !proValue.duration) {
          if(this.promotion.datestart && this.promotion.duration) {
            proValue = { duration: this.promotion.duration, unit: 'h' }
          }else{
            console.log('select la periode')
            return
          }

        }
        this.promotion.promotion_value = proValue
        var addday = parseInt(proValue.duration ) || proValue.duration
        if (proValue.unit === 'h') { addday = 0 }
       // if (proValue.unit === 'h') { addday = 1 }

        var date = new Date(this.promotion.datestart)
        var todate = date.addDays(addday)
        this.promotion.dateend = todate.getFullYear() + '-' + (todate.getMonth() + 1) + '-' + todate.getDate() + (this.promotion.time? (" " + todate.getHours() + ':' + todate.getMinutes()):'')
        // console.log(date.addDays(5));
        /* if (this.promotion.product) {

        } */
        if (this.promotion.promotion_type_id == 1) {
          console.log("vente flash")
          // vente flash
          // }else if (this.promotion.promotion_type.id == 6) {
          //     this.promotion.cost = this.promotion.promotion_value.cost * this.categoryselected.length
          this.newprice = true
          this.costlable = this.$t('cout prelevé à la vente')
          this.setPriceIntervale()
        } else {
          this.newprice = false
          this.costlable = this.$t('cout de la promotion')
        }
        if (this.promotion.categories_ids.length) {
          this.promotion.cost = (this.promotion.promotion_value.cost * this.promotion.categories_ids.length).toFixed(2)
          /* $('#promotion_cost').html((this.promotion.promotion_value.cost * this.categoryselected.length).toFixed(2)) */
        } else {
          this.promotion.cost = parseFloat(this.promotion.promotion_value.cost).toFixed(2)
          /* $('#promotion_cost').html((this.promotion.cost).toFixed(2)) */
        }
      },
      setPriceIntervale () {
        for (var i = 0; i < this.promotion_values.length; i++) {
          console.log(this.promotion_values[i], this.promotion)
          if ( parseFloat(this.promotion.promotion_price) >= parseFloat(this.promotion_values[i].price_min) &&
            parseFloat(this.promotion.promotion_price) <= parseFloat(this.promotion_values[i].price_max)) {
            setTimeout(() => {
              this.promotion.cost = parseFloat(this.promotion_values[i].cost)
            }, 500)
            this.setPromotionCost(this.promotion_values[i].cost)
            console.log(this.promotion_values[i].cost)
            // $("#promotion_cost").html(this.promotion_values[i].cost);
            this.promotion.promotion_value = this.promotion_values[i]
            this.promotion.cost = parseFloat(this.promotion_values[i].cost)
            break
          }
        }
        /*
        for (var i = 0; i < this.promotion_value_items.length; i++) {
          console.log(this.promotion_value_items[i], this.promotion)
          if (parseFloat(this.promotion_value_items[i].price_min) <= parseFloat(this.promotion.promotion_price) &&
            parseFloat(this.promotion.promotion_price) <= parseFloat(this.promotion_value_items[i].price_max)) {
            setTimeout(() => {
              this.promotion.cost = parseFloat(this.promotion_value_items[i].cost)
            }, 500)
            this.setPromotionCost(this.promotion_value_items[i].cost)
            console.log(this.promotion_value_items[i].cost)
            // $("#promotion_cost").html(this.promotion_value_items[i].cost);
            this.promotion.promotion_value = this.promotion_value_items[i]
            this.promotion.cost = parseFloat(this.promotion_value_items[i].cost)
            break
          }
        }*/
      },
      setPromotionCost (cost) {
        this.promotion.cost = parseFloat(cost)
      },
      getProduct () {
        this.loadingProduct = true
        axios.get('/products/' + this.$route.params.slug).then((response) => {
          if (response.data.id) {
            this.promotion.product = response.data
            this.promotion.product_id = response.data.id
            if (!this.promotion.id) {
              this.promotion.promotion_price = this.promotion.product.price
            }
          }
        }).then(() => {
          this.loadingProduct = false
        })
      },
      getCategories () {
        if (this.search.length < 3 || !this.loadingCategories) {
          this.loadingCategories = true
          axios.get('/categories/search', { params: { term: this.search, per_page: 50 } }).then((response) => {
            this.loadingCategories = false

            if (response.data.data && response.data.data.length) {
              for (let i = 0; i <= response.data.data.length; i++) {
                const d = response.data.data[i]
                if (d) {
                  const index = this.categories.findIndex((e) => e.id === d.id)
                  if (index < 0) {
                    this.categories.push(d)
                  }
                }
              }
            }
          })
        }
      },
      setCategoryselected (data) {
        console.log(data)
        this.categoryselected = data
        this.promotion.cost = this.promotion.promotion_value.cost * this.categoryselected.length
        $('#promotion_cost').html((this.promotion.promotion_value.cost * this.categoryselected.length).toFixed(2))
      },
      pvPromotiontype (parent) {
        this.promotion.cost = 0
        this.promotion_value_items = []
        console.log(this.promotion_values)
        for (var i = 0; i < this.promotion_values.length; i++) {
          if (parseInt(this.promotion_values[i].promotion_type_id) === this.promotion.promotion_type.id) {
            this.promotion_value_items.push(this.promotion_values[i])
          }
        }

        // this.promotion.promotion_price = this.product.price;

        return this.promotion_value_items
      },
      removepromotion (eqk, index) { // promotion.delete
        this.promotions.splice(index, 1)
        if (eqk.id) {
          axios.get(this.baseurl + '/promotion/delete/' + eqk.id, (response) => {
            console.log(response)
          })
        }
      },
      getTypes () {
        this.loadingTypes = true
        axios.get('/promotions_types', { params: { product: this.$route.params.slug } }).then((response) => {
          if (response.data && response.data.error) {
            this.snackbar.text = response.data.message || 'Erreur'
            this.snackbar.active = true
          } else {
            this.promotion_types = response.data.types
            this.promotion_values = response.data.values
          }
        }).then(() => {
          this.loadingTypes = false
        })
      },
      confirmProduct (item) {
        this.item = item
        this.confirm_dialog = true
      },
      deleteProduct () {
        this.loadingDelete = true
        axios.delete(`/promotions/${this.item.id}`).then((response) => {
          if (response.data && response.data.error) {
            this.snackbar.text = response.data.message || 'Erreur'
            this.snackbar.active = true
          } else {
            this.items.splice(this.items.findIndex(e => e.id === this.item.id), 1)
          }
        }).then(() => {
          this.loadingDelete = false
          this.confirm_dialog = false
        })
      },
      save () {
        const isFormValid = this.$refs.promotionForm.validate()
        if (!isFormValid) {
          this.toast(this.$t('Veuillez remplir tous les champs obligatoires.'), 'red')
          return false
        }
        if (this.promotion.promotion_type_id !== 1) {
          this.savePromotion()
        }else{
          this.confirm_dialog = true

        }


      },
      async savePromotion() {
        if (this.promotion.promotion_type_id !== 1) {
          //this.savePromotion()
        }else{
          const isFormValid = this.$refs.saveForm.validate()
          if (!isFormValid) {
            this.toast(this.$t('Veuillez remplir tous les champs obligatoires.'), 'red')
            return false
          }

        }




        this.loading = true
        axios({
          method: 'post',
          url: '/promotions',
          data: this.promotion,
          // eslint-disable-next-line no-unused-vars
          validateStatus: status => true,
        }).then(async (response) => {
          if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
            if (response.data.errors) {
              // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
              this.errorMessages = {...response.data.errors}
            } else {
              this.toast(response.data.message, 'red')

              // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
            }

            return false
          }

          if (this.promotion.promotion_type_id !== 1) {

              if (response.data.payment) {
                this.toast(this.$t('La promotion a été enregistré avec succès. Paiement...'), 'success')
                this.payment = response.data.payment
                this.clientSecret = this.payment.preregistration_data
                this.return_url = this.payment.redirect_url
               // this.validPaymentForm(payment)
                this.promotion.id = response.data.promotion.id
                this.showPayment = true

               this.getSettings()

              } else {
                this.toast(this.$t('Les données de la carte ne sont pas correctes. La promotion n\'a pas été enregistré avec succès.'), 'red')
              }


          } else {
            this.toast(this.$t('La promotion a été enregistré avec succès.'), 'success')
            this.$router.push('/promotions')
          }

          /* if(response.data.promotion) {
            this.promotion = response.data
            this.confirm_dialog = false
            this.$router.push("/promotions")
          }else{
            this.promotion = response.data
            this.confirm_dialog = false
            this.$router.push("/promotions")
          } */
        }).catch((err) => {
          this.loading = false
          return false
        }).then(() => {
          this.loading = false
        })
      },
      async handlePayment() {
        console.log("handle payment")
        const submitBtn = document.getElementById('submit');
        const handleError = (error) => {
          const messageContainer = document.querySelector('#error-message');
          messageContainer.textContent = error.message;
          submitBtn.disabled = false;
        }


        // Prevent multiple form submissions
        if (submitBtn.disabled) {
          return;
        }

        // Disable form submission while loading
        submitBtn.disabled = true;
        this.loadingSettings = true;
        // Trigger form validation and wallet collection
        const {paymentIntent,error} = await this.stripe.confirmPayment({
          elements: this.elements,
          redirect: 'if_required',
          confirmParams: { return_url: this.returnUrl },
        })
        if (error) {
          handleError(error);
          return;
        }
       // this.message = submitError ? error.message : "Redirecting...";


          this.loading = true
          const paymentIntentId = paymentIntent.id
          this.clientSecret = paymentIntent.client_secret
          const status = paymentIntent.status
          // eslint-disable-next-line no-undef
          axios({
            method: 'post',
            url: '/payments',
            data: {
              client_secret: this.clientSecret,
              payment_intent: paymentIntentId,
              status: status,
              id: this.payment.id,
            },
            // eslint-disable-next-line no-unused-vars
            validateStatus: status => true,
          }).catch(error => {
            this.loadingSettings = false;
            // this.loading = false
            this.loading = false
            console.log(error)
            // eslint-disable-next-line consistent-return
          })
            .then(async (response) => {
              this.loadingSettings = false;
              if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
                // ? Set user's data in localStorage for UI/Other purpose
                if (response.data.errors) {
                  // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
                  this.errorMessages = { ...response.data.errors }
                  this.error = response.data.errors
                  this.message = response.data.errors
                } else {
                  if (response.data.code) {
                    // this.$router.push(`/checkout?paymenterror=${response.data.message}`)
                  }
                  this.message = response.data.message
                  this.error = response.data.message
                  this.isSnackbarBottomVisible = true
                  this.snackBarMessage = response.data.message
                  this.snackBarColor = this.$vuetify.theme.currentTheme.error
                }
                // this.$router.push(`/checkout?paymenterror=${this.error}&order=${response.data.payment.payable_id}`)
                this.changing = false
                this.loadingSettings = false;
                return false
              }
              if (response.data.redirect) {
                this.loading = true
                this.message = this.$t('Validating payment')
                window.location.href = response.data.url
                return
                //var response1 = await axios.get(response.data.url)

              }
              this.isSnackbarBottomVisible = true
              this.snackBarColor = this.$vuetify.theme.currentTheme.success
              this.snackBarMessage = this.$t('Payment confirm')

              this.$router.push(response.data.url)

            })
            .then(() => {
              this.loadingSettings = false;
              this.loading = false
            })
            .catch(error => {
              this.loading = false
              this.loadingSettings = false;
              // TODO: Next Update - Show notification
              console.error('Oops, Unable to Reset!')
              console.log('error :>> ', error.response || '')
              // this.errorMessages = error.response.data.error
            })

      },
      validPaymentForm (payment) {

        this.loading = true
        // get manago payment token
        // eslint-disable-next-line no-undef
        /* var response = await axios.post(this.payment.card_registration_url, {
            accessKeyRef: this.payment.access_key,
            data: this.payment.preregistration_data,
            cardNumber: this.cardNumber,
            cardExpirationDate: this.cardExpirationDate,
            cardCvx: this.cardCvx,
          }) */

        var form = document.createElement('form')
        form.setAttribute('action', payment.card_registration_url)
        form.setAttribute('method', 'post')
        form.setAttribute('style', 'display: none')
        document.getElementsByTagName('body')[0].appendChild(form)

        // Add card registration data to the form
        form.appendChild(this.getInputElement('data', payment.preregistration_data))
        form.appendChild(this.getInputElement('accessKeyRef', payment.access_key))

        form.appendChild(this.getInputElement('cardNumber', this.cardNumber.replace(/ /g, '')))
        form.appendChild(this.getInputElement('cardExpirationDate', this.cardExpirationDate.replace('/', '')))
        form.appendChild(this.getInputElement('cardCvx', this.cardCvx))

        form.appendChild(this.getInputElement('returnURL', payment.redirect_url))

        // Submit the form
        form.submit()
        // this.loading = false
        /* if (response.data && response.data.error) {
            this.toast(this.$t(response.data.message), 'red')
          } else {
            this.payment.registration_data = response.data

            // go to payment page
            // return true */
      },
      getInputElement (name, value) {
        var input = document.createElement('input')
        input.setAttribute('type', 'hidden')
        input.setAttribute('name', name)
        input.setAttribute('value', value)
        return input
      },
      toast (message, color) {
        this.snackbar.text = message
        this.snackbar.color = color
        this.snackbar.active = true
      },
    },
  }
</script>
